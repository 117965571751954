import Cookies from 'js-cookie';
import docReady from '../../../assets/js/docReady';
import MeerClickTracker from './analytics/_meerclick';
import GoogleAnalyticsTracker from './analytics/_googleAnalytics';
import LinkTracker from './tracking/_linktracker';
import ChannelTracker from './tracking/_channelTracker';
import { getObjectFromLocalStorage } from '../../../assets/js/helpers/data';
import PostHog from './analytics/_posthog';

let analyticsClient;

/**
 * The main analytics class. This class instructs the various tracking libraries.
 */

/* eslint class-methods-use-this: ["error", { "exceptMethods": ["currentPageUrl", "mijnkeuzeStatus"] }] */
class Analytics {
    /**
     * Constructor. This method initializes tracking libraries based on the analytics configuration (banalytics variable)
     * @param analyticsConfig
     */
    constructor(analyticsConfig) {
        this.config = analyticsConfig;
        this.debugMode = (analyticsConfig.db === true);
        this.channel = null;
        this.channelGroup = null;
        this.allowPersonalization = true;
        this.sessionIdLength = 64;

        // Check whether debug mode should be enabled because of query option:
        const url = new URL(window.location);
        if (url.searchParams.has('banalytics_debug')) {
            if (url.searchParams.get('banalytics_debug') === 'true') {
                this.debugMode = true;
                this.storeTrackingVar('debug_mode', true);
            }
        }

        // Check whether debug mode should be enabled because local storage says so:
        if (this.retrieveTrackingVar('debug_mode', false) === true) {
            this.debugMode = true;
        }
    }

    setup() {
        // Retrieve session ID or create a new one:
        let sessionId = this.retrieveTrackingVar('mc_session');

        if (sessionId == null) {
            // Check MC cookie:
            sessionId = Cookies.get('m_session');

            if (typeof sessionId === 'undefined') {
                sessionId = this.generateRandomSessionId();
                this.log('Generated new session ID', sessionId);
            }
        }

        // Set session ID:
        this.setSessionId(sessionId);

        // Check whether consent is given/rejected for personalization:
        if (Cookies.get('cookieconsent_status') === 'accept_functional') {
            this.rejectConsent();
        }

        // Set up the link tracker:
        this.linkTracker = new LinkTracker(this);
        this.linkTracker.initialize();

        // Set up the channel tracker:
        this.channelTracker = new ChannelTracker(this);
        this.channelTracker.initialize();

        // Set up MeerClick:
        this.meerClick = new MeerClickTracker(this);
        this.meerClick.initialize();

        // Prepare Google Analytics:
        this.gaClient = new GoogleAnalyticsTracker(this);
        this.gaClient.initialize();

        // Prepare PostHog:
        this.posthogClient = new PostHog(this);
        this.posthogClient.initialize();

        // Provide a function to accept events from other libraries.
        // bananas is short for Better ANAlytics New Async event System
        window.bananas = (action, ...args) => this.bananas(action, ...args);

        // Register events from the eventQueue
        if (window.eventQueue !== undefined) {
            this.log('Lazy registering events', window.eventQueue);

            window.eventQueue.forEach((event) => {
                window.bananas(event.action, ...event.args);
            });
        }

        // Add two shorthands to make it easy to enable or disable debugging:
        window.bananas_on = () => {
            window.bananas('enable_debug');
        };
        window.bananas_off = () => {
            window.bananas('disable_debug');
        };
    }

    /**
     * Set channel
     * @param channel
     * @param channelGroup
     */
    setChannel(channel, channelGroup) {
        this.channel = channel;

        if (typeof channelGroup !== 'undefined' && channelGroup != null) {
            this.channelGroup = channelGroup;
        } else {
            const regex = /^([a-z0-9_]+)_[0-9]+$/;
            if (regex.test(this.channel)) {
                [, this.channelGroup] = regex.exec(this.channel);
            }
        }

        // Save channel to PostHog:
        if (typeof this.posthogClient !== 'undefined') {
            this.posthogClient.updateSessionTracking();
        }
    }

    /**
     * Get channel
     * @returns channel
     */
    getChannel() {
        return this.channel;
    }

    /**
     * Set session ID
     * @param sessionId
     */
    setSessionId(sessionId) {
        this.sessionId = sessionId;

        // For legacy purposes:
        window.global_session = sessionId;

        // Save session ID to local storage
        this.storeTrackingVar('mc_session', sessionId);

        // Save to cookie:
        Cookies.set('m_session', sessionId, { expires: 365 });

        // Save session ID to PostHog
        if (typeof this.posthogClient !== 'undefined') {
            this.posthogClient.updateSessionTracking();
        }
    }

    /**
     * Set view tracking ID (m_)
     * @param trackingId
     */
    setViewTrackingId(trackingId) {
        this.trackingId = trackingId;

        // For legacy purposes:
        window.global_tracking = trackingId;

        // Create cookie
        Cookies.set('m_view', this.trackingId);

        // Update PostHog
        this.posthogClient.updateSessionTracking();
    }

    /**
     * Log a debug message. Only shown when debug mode is enabled.
     * @param message Message
     * @param variable Optional extra variables
     */
    log(message, ...variable) {
        if (this.debugMode) {
            // eslint-disable-next-line no-console
            console.log(`🍌 ${message}`, JSON.stringify(...variable));
        }
    }

    /**
     * Log a debug message, large styling. Only shown when debug mode is enabled.
     * @param message Message
     * @param variable Optional extra variables
     */
    logLarge(message, ...variable) {
        if (this.debugMode) {
            // eslint-disable-next-line no-console
            console.log(`%c🍌 ${message}`, 'color: #f5aa0a; font-size: x-large', JSON.stringify(...variable));
        }
    }

    /**
     * Track an event
     * @param eventName Event name
     * @param eventData Event data
     */
    event(eventName, eventData) {
        this.logLarge(`Event "${eventName}"`, eventData);

        this.posthogClient.event(eventName, eventData);
        this.gaClient.event(eventName, eventData);
        this.meerClick.event(eventName, eventData);
    }

    /**
     * Track a virtual pageview. Used in SPA's (e.g. comparer)
     */
    virtualPageview() {
        this.logLarge('Virtual pageview ("pageview" event)');

        const eventData = {
            title: document.title,
            page_url: this.currentPageUrl(),
            page_url_query: this.currentPageUrl(true),
            channel: this.channel,
            virtual_pageview: true,
        };

        this.meerClick.pageview(() => {
            // Only log once meerclick has set an ID:
            this.posthogClient.trackPageView();
        });

        this.gaClient.virtualPageView(eventData);
    }

    /**
     * Track a pageview. Used once (usually).
     */
    pageview() {
        this.logLarge('Pageview ("pageview" event)');

        const eventData = {
            title: document.title,
            page_url: this.currentPageUrl(),
            page_url_query: this.currentPageUrl(true),
            channel: this.channel,
            virtual_pageview: false,
        };

        this.meerClick.pageview();

        // PostHog and Google Analytics are already tracking pageviews automatically
    }

    /**
     * Track a simple feedback. Used on bestChoice and Blog.
     */
    simpleFeedback(feedbackResult) {
        this.logLarge('Feedback ("feedback.simple" event)');

        const eventData = {
            title: document.title,
            page_url: this.currentPageUrl(),
            page_url_query: this.currentPageUrl(true),
            channel: this.channel,
            result: feedbackResult,
        };

        this.posthogClient.event('feedback.simple', eventData);
        this.gaClient.event('feedback.simple', eventData);
    }

    storeTrackingVar(variable, value) {
        if (typeof localStorage !== 'undefined') {
            this.log(`Storing ${variable} to local storage`, value);

            localStorage.setItem(`banalytics_${variable}`, JSON.stringify(value));
        } else {
            this.log(`Local storage not available. Not storing variable ${variable}`);
        }
    }

    retrieveTrackingVar(variable, defaultValue) {
        if (typeof localStorage !== 'undefined') {
            const value = localStorage.getItem(`banalytics_${variable}`);
            if (value !== null) {
                const parsedValue = JSON.parse(value);
                this.log(`Retrieved ${variable} from local storage`, parsedValue);

                return parsedValue;
            }
        }

        return defaultValue;
    }

    /**
     * Utility function to return the current page URL (just the path, no query parameters)
     */
    currentPageUrl(includeQuery) {
        if (includeQuery) {
            return window.location.pathname + window.location.search;
        }
        return window.location.pathname;
    }

    mijnkeuzeStatus() {
        if (!!getObjectFromLocalStorage('keuze-mijnkeuze') && !!getObjectFromLocalStorage('keuze-mijnkeuze').account.email_verified && !!getObjectFromLocalStorage('keuze-mijnkeuze').account.token) {
            return 'loggedIn';
        }

        if (!!getObjectFromLocalStorage('keuze-mijnkeuze') && !!getObjectFromLocalStorage('keuze-mijnkeuze').account.email_verified) {
            return 'loggedOut';
        }

        return 'unknown';
    }

    mijnkeuzeRegistrationStatus() { // @TODO: This is ugly, force registration complete status for registration funnel.
        if (this.mijnkeuzeStatus() !== 'unknown' && window.localStorage.getItem('keuze-mijnkeuze-registration-status') !== 'registering') {
            return 'complete';
        }
        if (!!window.localStorage.getItem('keuze-mijnkeuze-registration-status') && !!window.localStorage.getItem('keuze-mijnkeuze-registration-status').length) {
            return window.localStorage.getItem('keuze-mijnkeuze-registration-status');
        }

        return 'unknown'; // Fallback
    }

    /**
     * Called when user rejected personalization consent:
     */
    rejectConsent() {
        this.log('Rejected personalization consent');
        this.allowPersonalization = false;

        if (typeof this.gaClient !== 'undefined') {
            this.gaClient.disableAdPersonalizationSignals();
        }
    }

    /**
     * Provide a function to accept events from other libraries.
     * @param action Action type: event or pageview
     * @param args Event data (depends on action)
     */
    // eslint-disable-next-line consistent-return
    bananas(action, ...args) {
        switch (action) {
        case 'event':
            // Parse event
            if (args.length === 1) {
                if (typeof args[0] === 'string') {
                    // Pas event without parameters:
                    this.event(args[0], {});
                } else {
                    // Pass object
                    this.event(args[0].eventName, args[0]);
                }
            } else if (args.length === 2) {
                this.event(args[0], args[1]);
            } else {
                this.log('Invalid number of arguments');
            }
            break;
        case 'pageview':
            // Parse pageview (mainly useful for JS pageviews like vue-router)
            this.virtualPageview();
            break;
        case 'rejectConsent':
            this.rejectConsent();
            break;

        case 'enable_debug':
            this.debugMode = true;
            this.logLarge('Debug mode is enabled');
            this.storeTrackingVar('debug_mode', true);
            break;

        case 'disable_debug':
            this.logLarge('Debug mode is disabled');
            this.debugMode = false;
            this.storeTrackingVar('debug_mode', false);
            break;
        case 'channel':
            return this.getChannel();
        case 'update_session_tracking':
            this.posthogClient.updateSessionTracking();
            this.log('Manually updated session tracking');
            break;
        case 'simpleFeedback':
            return this.simpleFeedback(args[0]);

        case 'start_recording':
            this.posthogClient.startSessionRecording();
            break;

        case 'stop_recording':
            this.posthogClient.stopSessionRecording();
            break;

        default:
            this.log('No support for action', action);
        }
    }

    /**
     * Generate a random session ID
     * @returns {string}
     */
    generateRandomSessionId() {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;

        if (typeof window.crypto !== 'undefined') {
            const array = new Uint32Array(this.sessionIdLength);
            window.crypto.getRandomValues(array);

            // Convert array values to characters
            for (let i = 0; i < array.length; i += 1) {
                array[i] %= charactersLength;
            }

            for (let i = 0; i < array.length; i += 1) {
                result += characters.charAt(array[i]);
            }

            return result;
        }

        let counter = 0;

        while (counter < this.sessionIdLength) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }

        return result;
    }
}

docReady(() => {
    if (typeof window.banalytics === 'undefined') {
        // eslint-disable-next-line no-console
        console.warn('No banalytics configuration found; not initializing banalytics');
        return;
    }

    // Set up out analytics client
    analyticsClient = new Analytics(window.banalytics);
    analyticsClient.setup();

    // Track this pageview:
    analyticsClient.pageview();
});
